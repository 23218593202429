<template>
  <b-card>
    <b-overlay :show="loadingShow" rounded="lg" opacity="0.6">
      <b-row
        cols="1"
        class="page"
      >
        <b-col>
          <b-row
            cols="1"
            cols-sm="2"
            class="mb-2"
          >
            <b-col>
              <div class="pageTitle">
                {{ applicationData.nomeAplicacao }}
              </div>
              <div class="pageSubText">
                {{ applicationData.descricaoAplicacao }}
              </div>
            </b-col>
          </b-row>
        </b-col>
        <b-col>
          <TimedAlert
            v-if="applicationData.situacaoAplicacao === applicationStatus.emPausa"
            variant="primary"
            boldMessage="Aplicação pausada. "
            message="Para retomá-la, clique em Retomar Aplicação."
          />
          <TimedAlert
            v-if="applicationData.situacaoAplicacao === applicationStatus.cancelado"
            variant="danger"
            boldMessage="Aplicação cancelada. "
            message="Não é possível retornar essa aplicação!"
          />
        </b-col>
        <b-col>
          <b-row
            cols="1"
            cols-sm="2"
            class="mb-2"
          >
            <b-col class="mb-1">
              <div class="pageSubtitle">
                Nome da Aplicação
              </div>
              <div class="pageText">
                {{ applicationData.nomeAplicacao }}
              </div>
            </b-col>
            <b-col class="mb-1">
              <div class="pageSubtitle">
                Grupo
              </div>
              <div class="pageText">
                {{ applicationData.descricaoGrupoEmpresa }}
              </div>
            </b-col>
          </b-row>
        </b-col>
        <b-col>
          <b-row
            cols="1"
            cols-sm="2"
            class="mb-2"
          >
            <b-col class="mb-1">
              <div class="pageSubtitle">
                Empresa
              </div>
              <div class="pageText">
                {{ applicationData.razaoSocialEmpresa }}
              </div>
            </b-col>
            <b-col class="mb-1">
              <div class="pageSubtitle">
                Questionário
              </div>
              <div class="pageText">
                {{ applicationData.descricaoQuestionario }}
              </div>
            </b-col>
          </b-row>
        </b-col>
        <b-col>
          <b-row
            cols="1"
            cols-sm="2"
            cols-md="4"
            class="mb-1"
          >
            <b-col class="mb-1">
              <div class="pageSubtitle">
                Data de início
              </div>
              <div class="pageText">
                {{ applicationData.dataInicioAplicacao }}
              </div>
            </b-col>
            <b-col class="mb-1">
              <div class="pageSubtitle">
                Data de término
              </div>
              <div class="pageText">
                {{ applicationData.dataTerminoAplicacao }}
              </div>
            </b-col>
            <b-col class="mb-1">
              <div class="pageSubtitle">
                Horário de início
              </div>
              <div class="pageText">
                {{ applicationData.horarioInicioAplicacao }}
              </div>
            </b-col>
            <b-col>
              <div class="pageSubtitle">
                Horário de término
              </div>
              <div class="pageText">
                {{ applicationData.horarioTerminoAplicacao }}
              </div>
            </b-col>
          </b-row>
        </b-col>
        <b-col class="mb-2">
          <b-row
            cols="1"
            cols-sm="2"
          >
            <b-col>
              <div class="pageSubtitle">
                Descrição
              </div>
              <div class="pageText">
                {{ applicationData.descricaoAplicacao }}
              </div>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          class="d-flex flex-sm-row flex-column align-items-center justify-content-center"
        >
          <b-button
            v-if="applicationData.situacaoAplicacao === applicationStatus.agendado || applicationData.situacaoAplicacao === applicationStatus.emAndamento"
            class="mr-sm-2 mb-sm-0 mb-1"
            variant="custom"
            @click="disparaModalConfirmacao(applicationActionStatus.pausar)"
          >
            Pausar aplicação
          </b-button>
          <b-button
            v-if="applicationData.situacaoAplicacao === applicationStatus.emPausa"
            class="mr-sm-2 mb-sm-0 mb-1"
            variant="custom"
            @click="disparaModalConfirmacao(applicationActionStatus.retomar)"
          >
            Retomar aplicação
          </b-button>
          <b-button
            v-if="
              applicationData.situacaoAplicacao === applicationStatus.agendado ||
              applicationData.situacaoAplicacao === applicationStatus.emAndamento ||
              applicationData.situacaoAplicacao === applicationStatus.emPausa
            "
            class="mr-sm-2 mb-sm-0 mb-1"
            variant="custom"
            @click="disparaModalConfirmacao(applicationActionStatus.cancelar)"
          >
            Cancelar aplicação
          </b-button>
          <b-button
            variant="outline-primary"
            @click.once="returnToApplication"
          >
            Voltar para aplicação
          </b-button>
        </b-col>
      </b-row>
    </b-overlay>
  </b-card>
</template>

<script>
import { BCard, BOverlay, BRow, BCol, BButton } from 'bootstrap-vue'
import { modalGenericModel, modalGenericFillableErrorWithRedirectHome } from '@/libs/sweetalerts'
import TimedAlert from '@/views/components/custom/timed-alert/TimedAlert.vue'
import { $typeStatus, $typeActionStatus } from "./tab-components/dados-aplicacao-components/accessEnum.js";
import moment from 'moment'

export default {
  components: {
    BCard,
    BOverlay,
    BRow,
    BCol,
    BButton,
    TimedAlert
  },

  data() {
    return {
      applicationData: {
        idAplicacao: null,
        nomeAplicacao: "-",
        descricaoAplicacao: "-",
        descricaoGrupoEmpresa: "-",
        razaoSocialEmpresa: "-",
        descricaoQuestionario: "-",
        dataInicioAplicacao: "-",
        dataTerminoAplicacao: "-",
        horarioInicioAplicacao: "-",
        horarioTerminoAplicacao: "-",
        situacaoAplicacao: "-"
      },
      loadingShow: true,
      applicationStatus: $typeStatus,
      applicationActionStatus: $typeActionStatus
    }
  },

  mounted() {
    this.prepareApplicationData();
  },

  methods: {
    async prepareApplicationData() {
      if(
        this.$route.params.applicationData == null
      ){
        await modalGenericFillableErrorWithRedirectHome(
          'Não foi possível acessar a aplicação solicitada'
        )
      }else{
        this.applicationData.idAplicacao = this.$route.params.applicationData.id_aplicacao;
        this.applicationData.nomeAplicacao = this.$route.params.applicationData.nome_aplicacao;
        this.applicationData.descricaoAplicacao = this.$route.params.applicationData.descricao_aplicacao;
        this.applicationData.descricaoGrupoEmpresa = this.$route.params.applicationData.descricao_grupo_empresa;
        this.applicationData.razaoSocialEmpresa = this.$route.params.applicationData.razao_social_empresa;
        this.applicationData.descricaoQuestionario = this.$route.params.applicationData.descricao_questionario;
        this.applicationData.dataInicioAplicacao = this.$route.params.applicationData.data_inicio_aplicacao;
        this.applicationData.dataTerminoAplicacao = this.$route.params.applicationData.data_termino_aplicacao;
        this.applicationData.horarioInicioAplicacao = this.$route.params.applicationData.horario_inicio_aplicacao;
        this.applicationData.horarioTerminoAplicacao = this.$route.params.applicationData.horario_termino_aplicacao;
        this.applicationData.situacaoAplicacao = this.$route.params.applicationData.situacao_aplicacao;
        this.loadingShow = false;
      }
    },

    disparaModalConfirmacao(status){
      if(status === this.applicationActionStatus.pausar){
        this.disparaModalPausar()
      }else if(status === this.applicationActionStatus.retomar){
        if(moment(this.applicationData.dataTerminoAplicacao+" "+this.applicationData.horarioTerminoAplicacao, 'DD/MM/YYYY HH:mm:ss').isBefore(moment())){
          modalGenericModel(
            'Erro',
            'A data de término da aplicação é menor que a data atual, atualize a data de término e tente novamente.',
            require('@/assets/custom-icons/cora-icons/error.png'),
            'Ok'
          )
        }else{
          this.disparaModalRetomar()
        }
      }else if(status === this.applicationActionStatus.cancelar){
        this.disparaModalCancelar()
      }
    },

    async disparaModalCancelar(){
      let title = '';
      let text = '';
      if(this.applicationData.situacaoAplicacao === this.applicationStatus.agendado){
        title = 'Você está cancelando uma aplicação agendada';
        text = 'Esta ação fará com que a aplicação não seja iniciada na data prevista e seu status será definido como cancelado. Deseja continuar?';
      }else if(this.applicationData.situacaoAplicacao === this.applicationStatus.emAndamento){
        title = 'Você está cancelando uma aplicação em andamento';
        text = 'Esta ação fará com que a aplicação em andamento seja cancelada e seu status será definido como cancelado. Deseja continuar?';
      }else if(this.applicationData.situacaoAplicacao === this.applicationStatus.emPausa){
        title = 'Você está cancelando uma aplicação em pausa';
        text = 'Esta ação fará com que a aplicação em pausa seja cancelada e seu status será definido como cancelado. Deseja continuar?';
      }
      await modalGenericModel(
        title,
        text,
        require('@/assets/custom-icons/cora-icons/warning.png'),
        'Sim, cancelar a aplicação',
        'Não, quero manter a aplicação'
      ).then((result) => {
        if(result.isConfirmed){
          this.alteraStatus(this.applicationActionStatus.cancelar)
        }
      });
    },

    async disparaModalPausar(){
      let title = '';
      let text = '';
      if(this.applicationData.situacaoAplicacao === this.applicationStatus.agendado){
        title = 'Você está pausando uma aplicação agendada';
        text = 'Esta ação fará com que a aplicação não seja iniciada na data prevista e seu status será definido como pausado. Deseja continuar?';
      }else if(this.applicationData.situacaoAplicacao === this.applicationStatus.emAndamento){
        title = 'Você está pausando uma aplicação em andamento';
        text = 'Esta ação fará com que a aplicação em andamento seja pausada e seu status será definido como pausado. Deseja continuar?';
      }
      await modalGenericModel(
        title,
        text,
        require('@/assets/custom-icons/cora-icons/warning.png'),
        'Sim, pausar a aplicação',
        'Não, quero manter a aplicação'
      ).then((result) => {
        if(result.isConfirmed){
          this.alteraStatus(this.applicationActionStatus.pausar)
        }
      });
    },

    async disparaModalRetomar(){
      await modalGenericModel(
        'Tem certeza que deseja retomar?',
        'Esta ação fará com que a aplicação seja iniciada na data prevista e ' +
        'seu status será definido conforme a data programada para início. Deseja continuar?',
        require('@/assets/custom-icons/cora-icons/warning.png'),
        'Sim, retomar',
        'Cancelar'
      ).then((result) => {
        if(result.isConfirmed){
          this.alteraStatus(this.applicationActionStatus.retomar)
        }
      });
    },

    async alteraStatus(status){
      const params = {
        id_aplicacao: this.applicationData.idAplicacao,
        situacao: status
      }
      await this.$http.put(this.$api.cora.alteraStatusAplicacao, params)
      .then(() => {
        modalGenericModel(
          'Status alterado',
          'Status da aplicação alterado com sucesso!',
          require('@/assets/custom-icons/cora-icons/success.png'),
          'Ok'
        ).then(() => {
          this.returnToApplication()
        })
      }).catch(() => {
        modalGenericFillableErrorWithRedirectHome(
          'Não foi possível acessar a aplicação solicitada'
        )
      })
    },

    returnToApplication(){
      this.$router.push(
        {
          name: 'visualizar-aplicacao',
          params: { idAplicacao: this.applicationData.idAplicacao }
        }
      );
    }
  }
}
</script>
<style scoped>
  .page{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
  }
  .pageTitle{
    font-weight: 500;
    font-size: 28px;
    line-height: 34px;
    color: #5E5873;
  }
  .pageSubtitle{
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: #5E5873;
  }
  .pageText{
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #5E5873;
  }
  .pageSubText{
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #5E5873;
  }

  @media (max-width: 575px) {
    .page{
      text-align: center;
    }
  }
</style>

<template>
  <b-alert
    :variant="variant"
    dismissible
    :show="true"
    v-height-fade.appear
  >
    <div class="alert-body d-flex align-items-center">
      <feather-icon
        icon="AlertTriangleIcon"
        class="mr-50"
      />
      <p><b :class="'text-'+variant">{{ boldMessage }}</b>{{ message }}</p>
    </div>
  </b-alert>
</template>

<script>
import { BAlert } from 'bootstrap-vue'
import { heightFade } from '@core/directives/animations'

export default {
    components: {
      BAlert
    },

    directives: {
      heightFade
    },

    props: {
      boldMessage: {
        required: true,
        type: String,
      },
      message: {
        required: true,
        type: String,
      },
      variant: {
        type: String
      }
    }
}
</script>
